<template>
  <b-card>
    <div class="flex items-start">
      <b-button
        v-if="backButton"
        variant="primary"
        size="sm"
        class="mr-1 rounded-lg p-0"
        @click="$emit('back')"
      >
        <feather-icon
          size="2x"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <div class="text-black mb-2 text-2xl font-semibold">
        Pengajuan Packing
      </div>
    </div>
    <div class="space-y-6">
      <div class="border rounded-lg p-6 space-y-6">
        <b-row
          align-v="center"
          class="text-black"
        >
          <b-col cols="3">
            Upload Label Pengiriman
          </b-col>
          <b-col cols="4">
            <b-form-file
              v-model="file1"
              placeholder="Pilih file..."
              drop-placeholder="Taruh file di sini..."
              accept="application/pdf"
            />
          </b-col>
          <b-col cols="3">
            <b-button
              v-if="file1"
              variant="outline-primary"
              @click="previewFile()"
            >
              Preview
            </b-button>
          </b-col>
        </b-row>
        <b-row
          align-v="center"
          class="text-black"
        >
          <b-col cols="3">
            Total Order pada Resi
          </b-col>
          <b-col cols="4">
            <b-form-input
              v-model="totalOrderInput"
              placeholder="Inputkan jumlah order"
              :formatter="totalOrderField"
              :disabled="!confirmButton"
              number
            />
          </b-col>
          <b-col cols="3">
            <b-button
              :disabled="!totalOrderInput"
              :variant="!totalOrderInput ? 'secondary' : 'primary'"
              @click="confirmButton = !confirmButton"
            >
              {{ confirmButton ? 'Konfirmasi' : 'Ubah' }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="border rounded-lg p-6 space-y-6">
        <b-row
          align-v="center"
          class="text-black"
        >
          <b-col cols="3">
            Pilih Mitra Gudang
          </b-col>
          <b-col cols="4">
            <v-select
              key="id"
              v-model="warehouse"
              :options="listWarehouses"
              :reduce="option => option"
              :clearable="false"
              label="name"
              placeholder="Pilih gudang pengajuan packing"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div
              class="flex rounded-lg bg-[#DFF3FF] px-3.5 py-2.5 gap-2.5 items-start"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                alt="info-circle"
              >
              <div class="text-[#333]">
                Yuk buat rangkuman ordermu. Kamu hanya perlu input rangkuman
                orderanmu ya dengan cara input jenis isi paket dan jumlah
                ordernya.
                <a
                  href="https://www.youtube.com/watch?v=TqIfZ1DMVh4&t"
                  target="_blank"
                  class="text-[#08A0F7] underline hover:text-[#08A0F7]"
                >Lihat Tutorial</a>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-[#333] space-y-6">
            <div
              v-for="(packet, index0) in packetType"
              :key="index0"
              class="border rounded-lg p-6 space-y-4"
            >
              <div class="flex justify-between border-b pb-[16px]">
                <div>
                  Jenis Paket {{ index0 + 1 }}
                </div>
                <div class="flex gap-[12px]">
                  <b-button
                    v-if="packet.saved && index0 === packetType.length - 1"
                    size="sm"
                    variant="primary"
                    @click="packet.saved = false,
                            totalOrder = null, updateStock('increase')"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/edit.svg"
                      width="13"
                      alt="edit"
                    >
                  </b-button>
                  <b-button
                    v-if="packet.saved && index0 === packetType.length - 1 && packetType.length > 1"
                    size="sm"
                    variant="primary"
                    @click="packetType.splice(index0, 1), updateStock('increase')"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/trash.svg"
                      width="13"
                      alt="trash"
                    >
                  </b-button>
                  <b-button
                    v-if="!packet.saved && !packet.total"
                    size="sm"
                    variant="outline-primary"
                    @click="packetType.splice(index0, 1)"
                  >
                    Hapus
                  </b-button>
                  <b-button
                    v-if="!packet.saved"
                    size="sm"
                    :variant="addPacketContentDisabled(packet) || !packet.total || packet.total > packet.maxTotal ? 'secondary' : 'primary'"
                    :disabled="addPacketContentDisabled(packet) || !packet.total || packet.total > packet.maxTotal"
                    @click="packet.saved = true,
                            packet.order = index0 + 1,
                            updateStock('reduce')"
                  >
                    Simpan
                  </b-button>
                </div>
              </div>
              <!-- Label -->
              <b-row class="!mb-[-4px]">
                <b-col cols="4">
                  <div for="packet-product-id">
                    Isi Paket
                  </div>
                </b-col>
                <b-col cols="4">
                  <div for="packet-product-id">
                    Variasi
                    <img
                      v-b-tooltip.hover.top="'Variasi tidak dapat dipilih jika stok kosong'"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt="info-circle"
                      class="inline-flex"
                      width="16"
                    >
                  </div>
                </b-col>
                <b-col cols="2">
                  <div for="packet-product-id">
                    Jumlah Item
                    <img
                      v-b-tooltip.hover.top="'Jumlah item produk dalam satu paket'"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt="info-circle"
                      class="inline-flex"
                      width="16"
                    >
                  </div>
                </b-col>
                <b-col cols="2">
                  <div for="packet-product-id">
                    Jumlah Order
                    <img
                      v-b-tooltip.hover.top="'Total jumlah paket dengan isi yang sama'"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt="info-circle"
                      class="inline-flex"
                      width="16"
                    >
                  </div>
                </b-col>
              </b-row>
              <!-- Fields -->
              <b-row
                v-for="(item, index1) in packet.packetContent"
                :key="index1"
              >
                <!-- Isi Paket -->
                <b-col cols="4">
                  <v-select
                    v-model="item.option"
                    :options="packetContentOption"
                    :reduce="option => option"
                    :disabled="packet.saved"
                    :clearable="false"
                    placeholder="Pilih isi paket"
                    @input="packet.total = null,
                            item.variants = {}, item.amount = 1,
                            item.option.is_bundling ? item.totalVariant = item.option.variants.reduce((total, product) => total + product.qty, 0) : item.totalVariant = 1,
                            item.contentTotalCost = item.option.fulfillment_cost * item.amount * packet.total"
                  />
                </b-col>
                <!-- Variasi -->
                <b-col cols="4">
                  <div v-if="item.option">
                    <!-- Product non-bundling -->
                    <div v-if="!item.option.is_bundling">
                      <v-select
                        v-model="item.variants[0]"
                        :options="item.option.variants"
                        :reduce="option => option"
                        :selectable="(option) => option.stock > 0"
                        :clearable="false"
                        :disabled="packet.saved"
                        :label="item.option.is_variant ? 'name' : 'label'"
                        placeholder="Pilih Variasi"
                        @input="item.variants[0]['available'] = Math.floor((item.variants[0]['stock'] / item.amount)/item.variants[0]['qty']), removeSameVariant(packet.packetContent)"
                      />
                    </div>
                    <!-- Product bundling -->
                    <div
                      v-if="item.option.is_bundling"
                      class="space-y-5"
                    >
                      <div
                        v-for="(bundling, index2) in item.option.variants"
                        :key="index2"
                        class="space-y-5"
                      >
                        <div
                          v-for="qty in bundling.qty"
                          :key="qty"
                        >
                          <v-select
                            v-if="bundling.bundling_variants.length > 0"
                            v-model="item.variants[index2 * item.option.variants.length + qty - 1]"
                            :options="bundling.bundling_variants"
                            :placeholder="bundling.product_name"
                            label="name"
                            :disabled="packet.saved"
                            :clearable="false"
                            :selectable="(option) => option.stock > 0"
                            @input="item.variants[index2 * item.option.variants.length + qty - 1]['available'] = Math.floor((item.variants[index2 * item.option.variants.length + qty - 1]['stock'] / item.amount)/item.variants[index2 * item.option.variants.length + qty - 1]['qty']), removeSameVariant(packet.packetContent)"
                          />
                          <v-select
                            v-else
                            v-model="item.variants[index2 * item.option.variants.length + qty - 1]"
                            :options="[{ id: 0, product_id: bundling.product_id, name: bundling.product_name, stock: bundling.stock, qty: bundling.qty, label: bundling.product_name + ' : -'}]"
                            :placeholder="bundling.product_name"
                            :disabled="packet.saved"
                            :clearable="false"
                            :selectable="(option) => option.stock > 0"
                            @input="item.variants[index2 * item.option.variants.length + qty - 1]['available'] = Math.floor((item.variants[index2 * item.option.variants.length + qty - 1]['stock'] / item.amount)/item.variants[index2 * item.option.variants.length + qty - 1]['qty']), removeSameVariant(packet.packetContent)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-select
                    v-else
                    placeholder="Pilih Isi Paket Dulu"
                    disabled
                  />
                </b-col>
                <!-- Jumlah Item -->
                <b-col cols="2">
                  <b-form-input
                    v-model="item.amount"
                    :disabled="packet.saved || !item.option"
                    :formatter="formatNumber"
                    number
                    @input="packet.total = null,
                            handleFulfillmentContentCost(packet),
                            packet.total = null, Object.values(item.variants).forEach(variant => variant.available = Math.floor((variant.stock / item.amount)/variant.qty))"
                    @change="item.amount < 1 ? item.amount = 1 : item.amount,
                             packet.total = null, Object.values(item.variants).forEach(variant => variant.available = Math.floor((variant.stock / item.amount)/variant.qty))"
                  />
                </b-col>
                <!-- Jumlah Order -->
                <b-col cols="2">
                  <b-form-input
                    v-if="index1 === 0"
                    v-model="packet.total"
                    :disabled="packet.saved || addPacketContentDisabled(packet)"
                    :formatter="formatNumber"
                    number
                    @input="handleUsedStock(packet),
                            handleFulfillmentContentCost(packet)"
                  />
                  <div
                    v-if="index1 === 0 && !packet.saved"
                    class="mt-[4px] text-[12px] absolute"
                  >
                    <span
                      v-b-tooltip.hover.top="'Stok tersedia untuk membuat kombinasi jenis order'"
                      class="text-primary"
                    >Tersedia:</span>
                    <span v-if="!addPacketContentDisabled(packet)">
                      {{ packet.maxTotal = Math.min(...packet.packetContent.flatMap(item => Object.values(item.variants).map(variant => variant.available))) }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </div>
                </b-col>
              </b-row>
              <!-- Button tambah isi paket -->
              <b-row>
                <b-col class="space-x-4">
                  <b-button
                    v-if="!packet.saved"
                    size="sm"
                    :variant="addPacketContentDisabled(packet) ? 'outline-secondary' : 'outline-primary'"
                    :disabled="addPacketContentDisabled(packet)"
                    @click="pushEmptyPacketContent(packet), packet.total = null"
                  >
                    Tambah Isi Paket
                  </b-button>
                  <b-button
                    v-if="!packet.saved && packet.packetContent.length > 1"
                    size="sm"
                    variant="outline-primary"
                    @click="packet.packetContent.splice(packet.packetContent.length - 1, 1)"
                  >
                    Hapus Isi Paket
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <b-button
              :variant="somePacketTypeNotSaved() ? 'secondary' : 'primary'"
              :disabled="somePacketTypeNotSaved()"
              @click="setOrEmptyPacketType(false), totalOrder = null"
            >
              Tambah Jenis Paket
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="text-black">
        <b-row>
          <b-col cols="2">
            Total Order
          </b-col>
          <b-col cols="0">
            :
          </b-col>
          <b-col cols="9">
            <span v-if="somePacketTypeNotSaved()">
              0
            </span>
            <span v-else>
              {{ totalOrder = packetType.reduce((acc, obj) => {
                const valueToAdd = obj.total !== null && obj.total !== undefined ? obj.total : 0;
                return acc + valueToAdd;
              }, 0) }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            Total Biaya
          </b-col>
          <b-col cols="0">
            :
          </b-col>
          <b-col cols="9">
            <span v-if="somePacketTypeNotSaved()">
              Rp 0
            </span>
            <span v-else>
              {{ grandTotalCost = packetType.reduce((acc, order) => {
                order.packetContent.forEach(packet => {
                  acc += packet.contentTotalCost;
                });
                return acc;
              }, 0) | rupiah }}
            </span>
          </b-col>
        </b-row>
        <!-- Submit Button -->
        <b-button
          class="float-right mt-6"
          size="lg"
          :variant="!file1 || confirmButton || totalOrderInput !== totalOrder ? 'secondary' : 'primary'"
          :disabled="!file1 || confirmButton || totalOrderInput !== totalOrder || loading"
          @click="submitPacking()"
        >
          <b-spinner
            v-if="loading"
            class="mr-1"
            small
          />
          {{ loading ? 'Mengajukan...' : 'Ajukan Packing' }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { newAxiosIns } from '@/libs/axios'
import filters from '@/libs/filters'

export default {
  components: {
    vSelect,
  },
  filters: { rupiah: filters.rupiah },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    backButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file1: null,
      totalOrder: null,
      totalOrderInput: null,
      grandTotalCost: null,
      confirmButton: true,

      warehouse: null,
      listWarehouses: [],

      packetContentOption: [],

      packetType: [],

      loading: false,
    }
  },
  watch: {
    warehouse: {
      handler() {
        this.getProductWarehouse()
      },
    },
  },
  beforeMount() {
    this.getWarehouses()
    this.setOrEmptyPacketType(true)
  },
  methods: {
    removeSameVariant(items) {
      const uniqueItems = []
      const seen = new Set()

      items.forEach(item => {
        const { amount, ...itemWithoutAmount } = item
        const identifier = Object.values(itemWithoutAmount.variants).map(variant => `${variant.product_id}&${variant.id}`).join(',')
        if (!seen.has(identifier)) {
          seen.add(identifier)
          uniqueItems.push(item)
        }
      })

      // eslint-disable-next-line no-param-reassign
      items.length = 0
      uniqueItems.forEach(item => items.push(item))
    },
    previewFile() {
      const reader = new FileReader()

      reader.onload = e => {
        const pdfData = e.target.result

        const newTab = window.open()
        newTab.document.body.innerHTML = `<embed width="100%" height="100%" src="${pdfData}" type="application/pdf" />`
      }

      reader.readAsDataURL(this.file1)
    },
    totalOrderField(value) {
      // Remove non-digit characters and limit the length to 7 characters
      let processedValue = value
        ? value.replace(/[^\d]/g, '').slice(0, 7)
        : value

      // Ensure the value doesn't start with 0
      processedValue = processedValue.replace(/^0+/, '')

      return processedValue
    },
    formatNumber(value) {
      return value ? value.replace(/[^\d]/g, '').slice(0, 7) : value
    },
    async getWarehouses() {
      await newAxiosIns.get(`/komship/api/v1/warehouses/partners/${this.userData.partner_detail.id}`)
        .then(res => {
          this.listWarehouses = res.data.data
        })
        .catch(err => {
          console.error(err)
        })
    },
    updateStock(type) {
      const processedVariants = new Set()

      // Iterate through packetType
      this.packetType.forEach(packet => {
        packet.packetContent.forEach(content => {
          Object.values(content.variants).forEach(variant => {
            // Find matching variant in packetContentOption
            const matchingVariant = this.packetContentOption.find(option => option.variants.some(v => v.product_id === variant.product_id && v.id === variant.id))

            // If matching variant found and not already processed, reduce stock
            if (matchingVariant && !processedVariants.has(`${variant.product_id}&${variant.id}`)) {
              matchingVariant.variants.forEach(v => {
                if (v.product_id === variant.product_id && v.id === variant.id) {
                  // eslint-disable-next-line no-param-reassign
                  v.stock = type === 'reduce' ? v.stock - variant.usedStock : v.stock + variant.usedStock
                  // handle available
                  // eslint-disable-next-line no-param-reassign
                  v.available = Math.floor((v.stock / content.amount) / v.qty)
                  processedVariants.add(`${variant.product_id}&${variant.id}`)
                }
              })
            }
          })
        })
      })

      return this.packetContentOption
    },
    async getProductWarehouse() {
      this.setOrEmptyPacketType(true)
      this.packetContentOption = []
      this.totalOrder = null
      this.grandTotalCost = null
      const params = {
        keyword: '',
        limit: 1000,
        page: 1,
      }
      await newAxiosIns.get(`/komship/api/v1/product-warehouses/partners/${this.userData.partner_detail.id}/warehouses/${this.warehouse.id}`, { params })
        .then(res => {
          const { data } = res.data

          const mappedData = data.map(item => {
            const {
              // eslint-disable-next-line camelcase
              addon_cost, addon_minimum, product_weight_pbv, product_name, product_id, is_bundling, variants, fulfillment_cost = [],
            } = item

            const getVariants = () => {
              // eslint-disable-next-line camelcase
              if (is_bundling) {
                return item.product_bundle.map(product => ({
                  ...product,
                  bundling_variants: product.variants.map(variant => ({
                    ...variant,
                    product_id: product.product_id,
                    qty: product.qty,
                    available: 0,
                    usedStock: 0,
                  })),
                }))
              } if (variants.length > 0) {
                return variants.map(variant => ({
                  ...variant,
                  product_id,
                  qty: 1,
                  available: 0,
                  usedStock: 0,
                }))
              }
              return [{
                id: product_id,
                name: product_name,
                stock: item.stock,
                product_id,
                qty: 1,
                available: 0,
                usedStock: 0,
                label: product_name.concat(' : -'),
              }]
            }

            return {
              addon_cost,
              addon_minimum,
              product_weight_pbv,
              label: product_name,
              value: product_id,
              variants: getVariants(),
              is_bundling,
              is_variant: variants.length > 0,
              fulfillment_cost,
            }
          })

          this.packetContentOption = mappedData
        })
        .catch(err => {
          console.error(err)
        })
    },
    setOrEmptyPacketType(set) {
      const emptyPacket = {
        order: null,
        total: null,
        packetContent: [
          {
            amount: null,
            option: null,
            totalVariant: 0,
            contentTotalCost: 0,
            variants: {},
          },
        ],
        saved: false,
        maxTotal: 0,
      }

      this.packetType = set ? [emptyPacket] : [...this.packetType, emptyPacket]
    },
    pushEmptyPacketContent(packet) {
      packet.packetContent.push({
        amount: null,
        option: null,
        totalVariant: 0,
        contentTotalCost: 0,
        variants: {},
      })
    },
    addPacketContentDisabled(packet) {
      return packet.packetContent.some(item => !item.variants
      || !item.amount
      || !item.amount === ''
      || Object.keys(item.variants).some(key => item.variants[key] === null)
      || Object.keys(item.variants).length !== item.totalVariant)
    },
    somePacketTypeNotSaved() {
      return this.packetType.some(packet => !packet.saved)
    },
    submitPacking() {
      this.loading = true
      const mappedPackages = this.packetType.map(item => ({
        order_list: item.order,
        total_order: item.total,
        fulfillment_fee: item.packetContent.reduce((acc, obj) => acc + obj.contentTotalCost, 0),
        products: item.packetContent.map(packet => {
          const product = {
            total_item: packet.amount,
            product_id: packet.option.value,
            product_name: packet.option.label,
            product_variant_id: 0,
            variant_name: '',
            is_bundling: packet.option.is_bundling,
            product_bundles: [],
            product_fulfillment: packet.option.fulfillment_cost,
          }

          if (packet.option.is_bundling) {
            product.product_variant_id = 0
            product.variant_name = ''
            product.product_bundles = Object.values(packet.variants).map(variant => ({
              product_id: variant.product_id,
              product_name: variant.name.split(' : ')[0],
              product_variant_id: variant.id,
              variant_name: variant.name,
              qty: 1,
            }))
          } else {
            product.product_variant_id = packet.option.is_variant ? packet.variants[0].id : 0
            product.variant_name = packet.option.is_variant ? packet.variants[0].name : ''
          }

          return product
        }),
      }))

      const payload = {
        grand_fulfillment_fee: this.grandTotalCost,
        grand_total_order: this.totalOrder,
        mitra_id: this.warehouse.mitra_id,
        packages: mappedPackages,
        partner_id: this.userData.partner_detail.id,
        warehouse_id: this.warehouse.id,
      }

      newAxiosIns.post('/kompack/api/v1/outbounds', payload)
        .then(res => {
          const { data, meta } = res.data
          if (meta.code === 201) {
            const formData = new FormData()
            formData.append('label', this.file1)

            newAxiosIns.put(`/kompack/api/v1/outbounds/${data.outbound_id}/label`, formData)
              .then(() => {
                this.loading = false
                this.$swal({
                  title: 'Pengajuan Packing Berhasil',
                  text: 'Pengajuan packing akan segera diproses oleh mitra gudang',
                  icon: 'success',
                  iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-success.png">',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: 'Selesai',
                  customClass: {
                    icon: 'border-0 mb-[16px]',
                    confirmButton: 'btn btn-primary w-full',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.$router.push({
                      path: '/history-pickup',
                    })
                  }
                })
              })
              .catch(err => {
                console.error(err)
                this.$toast.error('Pengajuan packing gagal diajukan')
              })
          }
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.$swal({
            title: 'Pengajuan Packing Gagal',
            text: 'Pengajuan packing gagal karena saldomu tidak mencukupi. Yuk Top-Up saldomu sekarang',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Top-Up',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              icon: 'border-0 mb-[16px]',
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-primary mr-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push({
                path: '/keuangan/saldo',
              })
            }
          })
        })
    },
    handleUsedStock(packet) {
      Object.values(packet.packetContent).forEach(item => {
        Object.values(item.variants).forEach(variant => {
          // eslint-disable-next-line no-param-reassign
          variant.usedStock = variant.qty * item.amount * packet.total
        })
      })
    },
    handleFulfillmentContentCost(packet) {
      // Group items by the specified keys
      const groupedItems = {}
      packet.packetContent.forEach(item => {
        const key = `${item.option.fulfillment_cost}&${item.option.addon_minimum}&${item.option.addon_cost}&${item.option.product_weight_pbv}`
        if (!groupedItems[key]) {
          groupedItems[key] = []
        }
        groupedItems[key].push(item)
      })

      // Process each group of items
      Object.values(groupedItems).forEach(group => {
        // Calculate the total amount for the group
        const totalAmount = group.reduce((sum, item) => sum + item.amount, 0)

        // Calculate the cost for the total amount
        const sampleItem = group[0]
        const calculateCost = totalItems => {
          if (totalItems === 0) {
            return 0
          }
          const extraItems = totalItems - sampleItem.option.addon_minimum
          return sampleItem.option.fulfillment_cost + (Math.ceil(extraItems / sampleItem.option.addon_minimum) * sampleItem.option.addon_cost)
        }
        const totalCost = calculateCost(totalAmount) * packet.total

        // Assign the average cost to each item in the group
        const averageCost = totalCost / group.length
        group.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.contentTotalCost = averageCost
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
